import Navigation from '../navigation'

class Home {
  illustrations = document.querySelectorAll('.home__illustration')

  navigationLinks = document.querySelectorAll('.navigation__link:not(.navigation__link--is-parent)')

  static ANIMATION_DELAY = 7550

  static ACTIVE_ILLUSTRATION_CLASS = 'is-active'

  constructor() {
    this.navigation = new Navigation()
    this.onNavigationItemClick = this.onNavigationItemClick.bind(this)

    const homeCta = document.querySelector('.home__cta')
    homeCta.addEventListener('click', () => {
      const sectionApproach = document.querySelector('#approach')
      this.scrollToSection(sectionApproach)
    })

    this.enableSmoothNavigation()
    this.initAnimations()
  }

  initAnimations() {
    const totalIllustrations = this.illustrations.length
    let nextIllustration = 0

    window.setInterval(() => {
      if (nextIllustration + 1 === totalIllustrations) {
        nextIllustration = 0
      } else {
        nextIllustration++
      }

      this.selectIllustration(nextIllustration)
    }, Home.ANIMATION_DELAY)
  }

  selectIllustration(index = null) {
    if (index === null) return

    const activeIllustration = [...this.illustrations].filter(illustration => illustration.classList.contains(Home.ACTIVE_ILLUSTRATION_CLASS))
    activeIllustration[0].classList.remove(Home.ACTIVE_ILLUSTRATION_CLASS)

    const nextIllustration = this.illustrations[index]
    nextIllustration.classList.add(Home.ACTIVE_ILLUSTRATION_CLASS)
  }

  enableSmoothNavigation() {
    this.navigationLinks.forEach((link) => {
      link.addEventListener('click', this.onNavigationItemClick)
    })
  }

  onNavigationItemClick(event) {
    event.preventDefault()

    const { target } = event
    const sectionId = target.getAttribute('href')?.substr(1)
    const section = document.querySelector(sectionId)

    this.scrollToSection(section)
    this.navigation.close()
  }

  scrollToSection(section = null) {
    if (!section) return

    section.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }
}

new Home()
export default Home
